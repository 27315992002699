import React from "react"
import "./academy.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"

const WhyAcademy = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })

  const data = useStaticQuery(graphql`
    query {
      person: file(relativePath: { eq: "acadamy.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobilePerson: file(
        relativePath: { eq: "mobilePersonImage.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="aceindustrial_academy">
      <div className="container">
        <div className="row_main">
          <div className="academy_content">
            <h2 className="acdemy_title">
              Why Ace Industrial Academy?
            </h2>
            <p className="academy_description">
              Ace Industrial Academy is the leading training
              and certification center for BIM in Singapore.
              Since 2007, we have worked with consultants,
              contractors, and government agencies in the
              built-environment sector to provide skills
              training in BIM.
              <br />
              <br />
              We develop training programmes based on SSG
              guidelines that ensure that learners obtain
              the necessary skills to perform on their jobs.
              <br />
              <br />
              Find out more about{" "}
              <GatsbyLink to="/courses">
                our full list of courses.
              </GatsbyLink>
            </p>
          </div>
          <div className="acadamy_img">
            {/* {isSmallDevice ? (
              <Img
                fluid={
                  data.mobilePerson.childImageSharp.fluid
                }
              />
            ) : (
              <Img
                fluid={data.person.childImageSharp.fluid}
              />
            )} */}
            <StaticImage
              src="../../../assets/images/traning_3.png"
              alt=""
            />
            <div className="acadamyimg_content">
              <div className="learning_icon">
                <StaticImage
                  src="../../../assets/images/school.svg"
                  alt=""
                />
              </div>
              <div className="instiute_name">
                <p>
                  Training & Certification Center for Center
                  for Autodesk and Bentley Systems
                </p>
              </div>
            </div>

            <div className="acadamyimg_bottomcontent">
              <div className="learning_icon">
                <StaticImage
                  src="../../../assets/images/person.svg"
                  alt=""
                />
              </div>
              <div className="subs_count">
                <p>Established</p>
                <span className="student_title">
                  Since 1996
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyAcademy

import React from "react"
import "./training.scss"
import { useMediaQuery } from "react-responsive"
import CarouselSlider from "../../slider/Slider"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"

const TrainingCourses = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <div className="trainingSection">
      <div className="container">
        <div className="trainingSectionTitle">
          <h2 className="trainingTitle">
            See Training Courses from Different Categories
          </h2>
          <p className="trainingContent">
            All courses are taught by Industry
            Practitioners. Technical training and in-person
            instruction inclusive
          </p>
        </div>
        {!isTablet && (
          <div className="testiInner">
            <div className="traningBoxWrapper">
              <div className="trainingBox">
                <div className="trainingBoxIcon">
                  <StaticImage
                    src="../../../assets/images/autodesk-logo.png"
                    alt=""
                  />
                </div>
                <h3 className="trainingTitle">
                  Autodesk <span>BIM Courses</span>
                </h3>
                <div className="trainingButtonWrapper">
                  <GatsbyLink
                    className="trainingLink"
                    to="/courses-autodesk"
                  >
                    Explore{" "}
                    <span className="btnArrow">
                      <StaticImage
                        src="../../../assets/images/arrow.png"
                        alt=""
                      />
                    </span>
                  </GatsbyLink>
                </div>
              </div>

              <div className="trainingBox">
                <div className="trainingBoxIcon">
                  <StaticImage
                    src="../../../assets/images/b-logo.png"
                    alt=""
                  />
                </div>
                <h3 className="trainingTitle">
                  Bentley <span>BIM Courses</span>
                </h3>
                <div className="trainingButtonWrapper">
                  <GatsbyLink
                    className="trainingLink"
                    to="/courses-bentley"
                  >
                    Explore{" "}
                    <span className="btnArrow">
                      <StaticImage
                        src="../../../assets/images/arrow.png"
                        alt=""
                      />
                    </span>
                  </GatsbyLink>
                </div>
              </div>

              <div className="trainingBox">
                <div className="trainingIcon">
                  <div className="trainingBoxIcon">
                    <StaticImage
                      src="../../../assets/images/autodesk-logo.png"
                      alt=""
                    />
                  </div>
                  <div className="trainingBoxIcon">
                    <StaticImage
                      src="../../../assets/images/pearson vue logo.png"
                      alt=""
                    />
                  </div>
                </div>
                <h3 className="trainingTitle">
                  Autodesk Professional Certifications
                </h3>
                <div className="trainingButtonWrapper">
                  <GatsbyLink
                    className="trainingLink"
                    to="/courses-autodesk"
                  >
                    Explore{" "}
                    <span className="btnArrow">
                      <StaticImage
                        src="../../../assets/images/arrow.png"
                        alt=""
                      />
                    </span>
                  </GatsbyLink>
                </div>
              </div>
            </div>
          </div>
        )}
        {isTablet && (
          <div className="testiInner">
            <div className="traningBoxWrapper">
              <CarouselSlider
                slidesToShow={1}
                slidesToScroll={1}
                showSlideDots={false}
              >
                <div className="trainingBox">
                  <div className="trainingBoxIcon">
                    <StaticImage
                      src="../../../assets/images/autodesk-logo.png"
                      alt=""
                    />
                  </div>
                  <h3 className="trainingTitle">
                    Autodesk <span>BIM Courses</span>
                  </h3>
                  <div className="trainingButtonWrapper">
                    <GatsbyLink
                      className="trainingLink"
                      to="/courses-autodesk"
                    >
                      Explore{" "}
                      <span className="btnArrow">
                        <StaticImage
                          src="../../../assets/images/arrow.png"
                          alt=""
                        />
                      </span>
                    </GatsbyLink>
                  </div>
                </div>

                <div className="trainingBox">
                  <div className="trainingBoxIcon">
                    <StaticImage
                      src="../../../assets/images/b-logo.png"
                      alt=""
                    />
                  </div>
                  <h3 className="trainingTitle">
                    Bentley <span>BIM Courses</span>
                  </h3>
                  <div className="trainingButtonWrapper">
                    <GatsbyLink
                      className="trainingLink"
                      to="/courses-bentley"
                    >
                      Explore{" "}
                      <span className="btnArrow">
                        <StaticImage
                          src="../../../assets/images/arrow.png"
                          alt=""
                        />
                      </span>
                    </GatsbyLink>
                  </div>
                </div>

                <div className="trainingBox">
                  <div className="trainingIcon">
                    <div className="trainingBoxIcon">
                      <StaticImage
                        src="../../../assets/images/autodesk-logo.png"
                        alt=""
                      />
                    </div>
                    <div className="trainingBoxIcon">
                      <StaticImage
                        src="../../../assets/images/pearson vue logo.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <h3 className="trainingTitle">
                    Autodesk Professional Certifications
                  </h3>
                  <div className="trainingButtonWrapper">
                    <GatsbyLink
                      className="trainingLink"
                      to="/courses-autodesk"
                    >
                      Explore{" "}
                      <span className="btnArrow">
                        <StaticImage
                          src="../../../assets/images/arrow.png"
                          alt=""
                        />
                      </span>
                    </GatsbyLink>
                  </div>
                </div>
              </CarouselSlider>
            </div>
          </div>
        )}

        {/* <div className="testiInner">
          <div className="traningBoxWrapper">
            <div className="trainingBox">
              <div className="trainingBoxIcon">
                <StaticImage
                  src="../../../assets/images/aws-training.png"
                  alt=""
                />
              </div>
              <h3 className="trainingTitle">
                Amazon<span>Web Services</span>
              </h3>
              <div className="trainingButtonWrapper">
                <GatsbyLink
                  className="trainingLink"
                  to="/courses-aws"
                >
                  Explore{" "}
                  <span className="btnArrow">
                    <StaticImage
                      src="../../../assets/images/arrow.png"
                      alt=""
                    />
                  </span>
                </GatsbyLink>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default TrainingCourses

import React from "react"
import "./goTraining.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
// import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"

const WhyShouldIGoTraining = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })

  return (
    <div className="goTraining_container">
      <div className="container">
        <h2 className="acdemy_title">
          Why should I go for training?
        </h2>
        <div className="row_main">
          <div className="training_img">
            <StaticImage
              src="../../../assets/images/traning_1.png"
              alt=""
            />
          </div>
          <div className="training_content">
            <h3>Fresh Graduate</h3>
            <p className="academy_description">
              Our short training courses are suitable for
              aspiring and existing professionals to
              Singapore's Built Environment. Fresh graduates
              will find that our course offers you a glimpse
              into the digital workspace of the Built
              Environment. This allows you to be more
              intentional and deliberate about your
              intention to establish a career in our Built
              Environment.
            </p>
            <br />
            <h3>Professional</h3>
            <p className="academy_description">
              Existing professionals will find that our
              course is developed with a focus on
              transferability of BIM and CAD skills to your
              projects. Simply take a few days off work to
              train with us, and you will find that you
              return to your projects having closed a
              knowledge gap and do even better at your
              workplace.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyShouldIGoTraining

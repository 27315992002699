import React from "react"
import "./goTraining.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
// import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"

const WhySendMyStuff = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })

  return (
    <div className="sendMyStaff_container">
      <div className="container">
        <div className="row_main">
          <div className="academy_content">
            <h2 className="acdemy_title">
              Why send my staff for training?
            </h2>
            <p className="academy_description">
              Every new hire requires the time and attention
              of one or more senior staff in your
              organisation to complete the training and
              onboarding process. New hires may also have
              knowledge gaps that needs to be addressed
              before they can start pulling their weights on
              project delivery. Existing professionals in
              your team that needs reskilling also exhausts
              time and energy from a more experienced staff.
              <br />
              <br />
              Sending your staff for training with us means
              you save time and energy of precious human
              resource in your company to focus on project
              delivery, while your new hire or existing
              professional is, in tandem, trained up to
              speed.
            </p>
          </div>
          <div className="acadamy_img">
            <StaticImage
              src="../../../assets/images/training_2.png"
              alt=""
            />
            <div className="learningImg_content">
              <div className="learning_icon">
                <StaticImage
                  src="../../../assets/images/square_aim.svg"
                  alt=""
                />
              </div>
              <div className="instiute_name">
                <p>Focus on Transferability</p>
              </div>
            </div>

            <div className="learningImg_bottomcontent">
              <div className="learning_icon">
                <StaticImage
                  src="../../../assets/images/circle_check.svg"
                  alt=""
                />
              </div>
              <div className="subs_count">
                {/* <p>Do Better</p> */}
                <span className="student_title">
                  Do Better
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhySendMyStuff

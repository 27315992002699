import React from "react"
import "./partners.scss"
import { graphql, useStaticQuery } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"
// import { Carousel, ResponsiveContext } from "grommet"
import Img from "gatsby-image"

const Partners = () => {
  //   const { allContentfulCategory } = useStaticQuery(
  //     graphql`
  //       query {
  //         allContentfulCategory {
  //           nodes {
  //             id
  //             name
  //             icon {
  //               fluid {
  //                 ...GatsbyContentfulFluid
  //               }
  //             }
  //           }
  //         }
  //       }
  //     `
  //   )

  //   const partners = allContentfulCategory.nodes

  //   const size = useContext(ResponsiveContext)

  //   const renderPartners = () => {
  //     if (size !== "small") {
  //       return (
  //         <>
  //           {partners?.map((partner, i) => (
  //             <li key={i}>
  //               <Img
  //                 fluid={partner?.icon.fluid}
  //                 alt={partner?.name}
  //               />
  //             </li>
  //           ))}
  //         </>
  //       )
  //     }
  //     return (
  //       <Carousel>
  //         {partners?.map((partner, i) => (
  //           <li key={i}>
  //             <Img
  //               fluid={partner?.icon.fluid}
  //               alt={partner?.name}
  //             />
  //           </li>
  //         ))}
  //       </Carousel>
  //     )
  //   }

  const data = useStaticQuery(graphql`
    query {
      autodesk: file(relativePath: { eq: "autodesk2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bentely: file(relativePath: { eq: "bentely2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bimcollab: file(relativePath: { eq: "bimcollab2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      buildingsmart: file(relativePath: { eq: "buildingsmart2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tekla: file(relativePath: { eq: "tekla1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="CompanyLogoSection">
      <div className="container">
        <div className="logoWrapper">
          <ul className="logoList">
            {/* {renderPartners()} */}
            <li>
              <Img
                fluid={data.autodesk.childImageSharp.fluid}
              />
            </li>
            <li>
              <Img
                fluid={data.bentely.childImageSharp.fluid}
              />
            </li>
            <li>
              <Img
                fluid={data.bimcollab.childImageSharp.fluid}
              />
            </li>
            <li>
              <Img
                fluid={data.buildingsmart.childImageSharp.fluid}
              />
            </li>
            <li>
              <Img
                fluid={data.tekla.childImageSharp.fluid}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Partners

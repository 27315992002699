import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Home from "../components/home/Home"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

const Index = ({ data }) => {
  const {
    featured,
    courses,
    autodeskCourses,
    bentleyCourses,
  } = data
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    dispatch({
      type: "SET_COURSES",
      payload: Object.values(courses.nodes),
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: "SET_FEATURED",
      payload: Object.values(featured.nodes),
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: "SET_AUTODESK",
      payload: Object.values(autodeskCourses.nodes),
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: "SET_BENTLEY",
      payload: Object.values(bentleyCourses.nodes),
    })
  }, [])

  return (
    <>
      <SEO
        title="Top BIM & CAD courses and training in Singapore | Ace Industrial Academy"
        description="Ace Industrial Academy is the leading BIM training in Singapore. We have a wide range of BIM & CAD training courses that will help you learn more about BIM for construction design."
      />
      <Home featured={featured.nodes} />
    </>
  )
}

export default Index

export const query = graphql`
  query {
    featured: allContentfulTraining(
      filter: { category: { eq: "featured" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        duration
        product {
          id
          name
          netFee
          price
        }
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
    courses: allContentfulTraining {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        duration
        overview
        product {
          id
          name
          netFee
          price
        }
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
    autodeskCourses: allContentfulTraining(
      filter: { category: { eq: "autodesk" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        product {
          id
          name
          netFee
          price
        }
        duration
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
    bentleyCourses: allContentfulTraining(
      filter: { category: { eq: "bentley" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        product {
          id
          name
          netFee
          price
        }
        duration
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

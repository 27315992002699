import React, { useState } from "react"
import {
  StaticImage,
  GatsbyImage,
  getImage,
} from "gatsby-plugin-image"
import "./itemCard.scss"
import { Link as GatsbyLink } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import CourseRegistrationForm from "../../../components/product-detail/CourseRegistration/courseRegistrationForm"
import Logo from "../../../assets/aia-logo"

const ItemCard = (props) => {
  const {
    isCertificate,
    isAutodeskCertificate,
    title,
    courseReference,
    netFee,
    trainingMode,
    duration,
    category,
    slug,
    coverPhoto,
    courseRuns,
  } = props

  const image = getImage(coverPhoto)

  const cardTitle = isAutodeskCertificate
    ? title
    : "AWS Developer - Associate Exam"

  const data = useStaticQuery(graphql`
    query {
      cources: file(relativePath: { eq: "cources.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      autocad: file(
        relativePath: { eq: "autodesk-certificate.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aws: file(
        relativePath: { eq: "aws-certificate.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const handleRegisterClick = () => {
    toggle()
    window.localStorage.setItem("selected-course", title)
    window.localStorage.setItem("Price", netFee)
  }

  return (
    <>
      <div className="cources_card">
        <GatsbyLink to={`/courses/${slug}`}>
          <div className="cources_img">
            {coverPhoto ? (
              <GatsbyImage image={image} alt="" />
            ) : (
              <Img
                fluid={data.cources.childImageSharp.fluid}
              />
            )}
          </div>
          <div className="courcesbrif_info">
            <div className="autobasis_brn">
              {category
                ? category.map((tag, i) => (
                    <span key={i} className="autodesk_btn">
                      {tag}
                    </span>
                  ))
                : null}
            </div>
            <div className="corces_review">
              <StaticImage
                src="../../../assets/images/Star.svg"
                alt=""
              />
              <StaticImage
                src="../../../assets/images/Star.svg"
                alt=""
              />
              <StaticImage
                src="../../../assets/images/Star.svg"
                alt=""
              />
              <StaticImage
                src="../../../assets/images/Star.svg"
                alt=""
              />
              <StaticImage
                src="../../../assets/images/Star.svg"
                alt=""
              />
            </div>
            <div className="coucesname_price">
              <h6 className="cource_title">
                {title}
                <br />
                {courseReference && `(${courseReference})`}
              </h6>
              <span className="cource_price">
                {netFee && `$${netFee}`}
              </span>
            </div>
            <div className="coursetime_location">
              <div className="cources">
                <StaticImage
                  src="../../../assets/images/clock.svg"
                  alt=""
                />
                <span className="clocknamename">
                  {duration}
                </span>
              </div>
              <div className="cources">
                <StaticImage
                  src="../../../assets/images/location.svg"
                  alt=""
                />
                <span className="clocknamename">
                  {trainingMode}
                </span>
              </div>
            </div>
          </div>
        </GatsbyLink>
        <div className="foot-btn">
          {/* <button
            className="btnquota"
            onClick={handleRegisterClick}
          >
            Get A Quote
          </button> */}
          <button className="register">
            <a href={courseRuns} className="menuLink">
              Register Now
            </a>
          </button>
        </div>
        <div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <GatsbyLink to="/">
                <Logo />
              </GatsbyLink>
            </ModalHeader>
            <ModalBody>
              <CourseRegistrationForm
                price={netFee}
                selectedCourse={title}
                coverPhoto={coverPhoto}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default ItemCard

import React, { useContext } from "react"
import "./courses.scss"
import { Link as GatsbyLink } from "gatsby"
import CarouselSlider from "../../slider/Slider"
import { useMediaQuery } from "react-responsive"
import ItemCard from "../../common/ItemCard/ItemCard"

const PopularCourses = (props) => {
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  })

  const { featured } = props

  return (
    <div className="mostpopular_detailes">
      <div className="container">
        <div className="populartitles_detailes">
          <div className="row_main">
            <div className="mostpopularleft_titledata">
              <h2 className="courses_title">
                Check out our most Popular Courses
              </h2>
              <p className="courses_description">
                All Courses are ISO-Compliant BIM Courses
              </p>
            </div>
            <div className="allcourses_btn">
              <GatsbyLink
                to="/courses"
                className="seecorses"
              >
                See All courses
              </GatsbyLink>
            </div>
          </div>
        </div>
        <div className="corces_data">
          <div className="row_main">
            {isTablet ? (
              <CarouselSlider
                slidesToShow={isMobile ? 1 : 2}
                slidesToScroll={isMobile ? 1 : 2}
                showSlideDots={false}
              >
                {featured
                  ? featured.map((item, i) => (
                      <ItemCard
                        key={i}
                        slug={item.slug}
                        coverPhoto={item.coverPhoto}
                        title={item.title}
                        category={item.category}
                        courseReference={
                          item.courseReference
                        }
                        netFee={item.product?.netFee}
                        duration={item.duration}
                        trainingMode={item.trainingMode}
                        courseRuns={item.courseRuns}
                      />
                    ))
                  : null}
              </CarouselSlider>
            ) : (
              <>
                {featured
                  ? featured.map((item, i) => (
                      <ItemCard
                        key={i}
                        slug={item.slug}
                        coverPhoto={item.coverPhoto}
                        title={item.title}
                        category={item.category}
                        courseReference={
                          item.courseReference
                        }
                        netFee={item.product?.netFee}
                        duration={item.duration}
                        trainingMode={item.trainingMode}
                        courseRuns={item.courseRuns}
                      />
                    ))
                  : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopularCourses

import React from "react"
import HeroBanner from "./heroBanner/HeroBanner"
import "./home.scss"
import TrainingCourses from "./trainingCourses/TrainingCourses"
import WhyAcademy from "./whyAcademy/WhyAcademy"
import WhyShouldIGoTraining from "./whyShouldIGoTraining/WhyShouldIGoTraining"
import WhySendMyStuff from "./whySendMyStaff/WhySendMyStuff"
import PopularCourses from "./popularCourses/PopularCourses"
import Testimonials from "./testimonials/Testimonials"
import Subscription from "./subscriptionSection/Subscription"
import Partners from "./partners/Partners"

const Home = (props) => {
  const { featured } = props
  return (
    <div className="homeContainer">
      <HeroBanner />
      <TrainingCourses />
      <Partners />
      <WhyAcademy />
      <WhyShouldIGoTraining />
      <WhySendMyStuff />
      <PopularCourses featured={featured} />
      <Testimonials />
      <Subscription />
    </div>
  )
}

export default Home
